import { DateTime } from 'luxon'

export function getFiscalYearName(fiscalStartDate, fiscalDuration) {
  // Extract month and year from currentDate
  const currentMonth = fiscalStartDate.getMonth() // JavaScript months are zero-indexed
  const currentYear = fiscalStartDate.getFullYear()

  fiscalStartDate = new Date(currentYear, currentMonth)

  const dateTo = addMonthsToDate(fiscalStartDate, fiscalDuration - 1)

  let fiscalStartYear, fiscalEndYear

  fiscalStartYear = fiscalStartDate.getFullYear()
  fiscalEndYear = dateTo.getFullYear()

  // Format fiscal year as needed
  let fiscalYear
  if (fiscalStartYear === fiscalEndYear) {
    fiscalYear = fiscalStartYear.toString() // Output single year
  } else {
    fiscalYear = `${fiscalStartYear}-${fiscalEndYear.toString().slice(2)}`
  }
  return fiscalYear
}

export function addMonthsToDate(date, monthsToAdd) {
  // Clone the original date to avoid modifying it directly
  let newDate = new Date(date)

  // Add the specified number of months
  newDate.setMonth(newDate.getMonth() + monthsToAdd)

  return newDate
}

export function getThisMonthDates() {
  const today = new Date()
  let firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 1)
  let lastDayOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0)

  firstDayOfMonth = new Date(firstDayOfMonth.setHours(0, 0, 0, 0))
  lastDayOfMonth = new Date(lastDayOfMonth.setHours(0, 0, 0, 0))

  return [firstDayOfMonth, lastDayOfMonth]
}

export function getMonthName(monthNumber) {
  return DateTime.local().set({ month: monthNumber }).monthLong
}

export function getMonthAbbr(monthNumber) {
  return DateTime.local().set({ month: monthNumber }).monthShort
}

// get Fiscal Year Dates, passing isLastDayOfFY to indicate get up to today's date or last day of FY
export function getFiscalYearDates(fiscalStart, fiscalDuration, isLastDayOfFY = false) {
  let fiscalStartMonth = fiscalStart - 1
  let fiscalYearStartDate = new Date(new Date().getFullYear(), fiscalStartMonth, 1)

  let today = new Date() // Initialize current date
  let dateTo = today

  const todayYear = today.getFullYear()
  const yearStartYear = fiscalYearStartDate.getFullYear()

  if (today < fiscalYearStartDate || todayYear !== yearStartYear)
    fiscalYearStartDate.setFullYear(fiscalYearStartDate.getFullYear() - 1)

  // add duration to start date to get date to
  if (isLastDayOfFY) {
    dateTo = new Date(fiscalYearStartDate)
    dateTo = new Date(dateTo.setMonth(dateTo.getMonth() + fiscalDuration))

    // Set the date to the last day
    dateTo.setDate(0)
  }

  fiscalYearStartDate = new Date(fiscalYearStartDate.setHours(0, 0, 0, 0))
  dateTo = new Date(dateTo.setHours(0, 0, 0, 0))

  return [fiscalYearStartDate, dateTo]
}

// get fiscal year dates up to today's date
export function getFiscalYearDates_UpToToday(fiscalStart) {
  let fiscalStartMonth = fiscalStart - 1
  let fiscalYearStartDate = new Date(new Date().getFullYear(), fiscalStartMonth, 1)
  let today = new Date() // Initialize current date

  // Check if today is before the fiscal year start date
  if (today < fiscalYearStartDate) {
    fiscalYearStartDate.setFullYear(today.getFullYear() - 1) // If so, adjust fiscal year start date to previous year
  }

  // Set time part of dates to 00:00:00
  fiscalYearStartDate = new Date(fiscalYearStartDate.setHours(0, 0, 0, 0))
  today = new Date(today.setHours(0, 0, 0, 0))

  return [fiscalYearStartDate, today]
}

// function getLastDayOfFiscalYear(fiscalStartMonth, fiscalDuration) {
//   const currentDate = new Date() // Assuming this is the current date

//   // Determine the fiscal year start date
//   let fiscalYearStartDate
//   if (fiscalStartMonth === 1) {
//     fiscalYearStartDate = new Date(currentDate.getFullYear(), 0, 1)
//   } else {
//     fiscalYearStartDate = new Date(currentDate.getFullYear(), fiscalStartMonth - 1, 1)
//   }

//   // Add the fiscal duration to find the fiscal year end date
//   let fiscalYearEndDate = new Date(fiscalYearStartDate.getFullYear(), fiscalYearStartDate.getMonth() + fiscalDuration, 0)
//   return fiscalYearEndDate
// }
